.header-container {
    justify-content: space-between;
    align-items: center;
    background-color: #FDD4E2;

    &.mobile-nav {
        .drawer-con {
            cursor: pointer;
        }
    }

    a {
        padding: 0 25px;
        text-decoration: none;
        color: #000;
        font-family: 'Montserrat';
        font-weight: 600;
    }
}

.header-container, .drawer-nav-header {
    height: 10vh;
    padding: 16px 10vw;
    position: sticky;
    top: 0;
    z-index: 9999;
}

.header-column {
    height: inherit;
    align-items: center;
}

.drawer-wrapper {
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100vw;
    transition: left 0.2s;
    pointer-events: none;
    
    &.visible {
        left: 0;
        pointer-events: all;

        .drawer-nav {
            box-shadow: 16px 10px 115px 10px rgba(0,0,0,0.75);
        }
    }

    &.hidden {
        left: -1000px;
    }

    .drawer-background {
        width: 40%;
    }
    
    .drawer-nav {
        background-color: white;
        flex-direction: column;
        font-size: 36px;
        width: 60%;
    
        a {
            margin: 10px 0;
        }
    }
}


@media only screen and (max-width: 700px) {
    .header-container, .drawer-nav-header {
        padding: 16px 5vw;
    }
}