@font-face {
  font-family: 'Quicksand';
  src: url('./assets/fonts/quicksand/Quicksand-VariableFont_wght.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Great_Vibes';
  src: url('./assets/fonts/Great_Vibes/GreatVibes-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Chicle';
  src: url('./assets/fonts/Chicle/Chicle-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

body {
  background-color: #FDF6D4;
  font-family: "Quicksand";
  margin: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

div#root {
  display: block;
}

div.index {
  display: block;
}

div {
  display: flex;
}

.splash-title {
  color: #F29F05;
}