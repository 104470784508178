.home-container {
    width: 100%;
    position: relative;

    .bio-mobile {
        display: none;
    }

    blockquote div {
        display: block;
    }

    .button {
        font-family: "Chicle";
        font-size: 24px;

        border-radius: 8px;
        border: 1px solid black;

        display: block;
        width: fit-content;
        padding: 16px;
        margin: 10px 0;

        background-color: #D4E7FD;
        color: black;
        text-decoration: none;

        cursor: pointer;
    }

    .splash-image-container {
        position: absolute;
        width: 100%;
        height: 100%;
        pointer-events: none;

        .splash-image {
            width: 100%;
            height: 100%;
            // background-image: url('../assets/img/splash.jpg');
            background-repeat: no-repeat;
        }
    }

    .center-card {
        margin-left: 7vw;
        margin-top: 50px;
        width: 100%;
        flex-wrap: wrap;

        .details-column {            
            .home-title {
                width: 90%;
            }

            .bio {
                flex-direction: column;
                width: 80%;
                background-color: #D5CEA9;
                border-radius: 8px;
                padding: 0 10px;
                font-weight: 600;
                margin-bottom: 10px;
                font-size: 18px;
                line-height: 1.5;
            }
        }

        .works-preview {
            margin-right: 5vw;
            // background-color: #D4E7FD;
            // border: 1px solid #A6B4C3;
            flex-direction: column;
            max-width: 100%;

            .subtitle {
                justify-content: space-between;
                align-items: baseline;
                width: 100%;

                .subtitle-title {
                    font-family: "Chicle";
                    font-size: 40px;
                    font-weight: bold;
                }

                .subtitle-end {
                    color: #A03737;
                    font-weight: bold;
                    font-size: 20px;
                    text-decoration: none;
                }

            }

            .works-previews {
                flex-wrap: wrap;
                justify-content: space-around;

                img {
                    width: 10vw;
                    height: 10vw;
                    margin: 1vw;
                }
            }
        }
    }

}

@media only screen and (max-width: 700px) {
    .home-container {
        .center-card {
            .details-column {
                .bio {
                    display: none;
                }
            }

            .works-preview {
                width: 90%;
            }
        }

        .bio-mobile {
            display: flex;
            width: 90%;
            flex-direction: column;
            background-color: #D5CEA9;
            border-radius: 8px;
            padding: 0 10px;
            font-weight: 600;
            margin-bottom: 10px;
        }
    }

    .instagram-media>div {
        padding: 0 !important;
    }
}