.contact-container {
    padding: 16px;

    .error-msg {
        color: red;
        text-align: center;
    }

    .form-state {
        text-align: center;
        width: 100%;
    }

    .form-container {
        width: 100%;
        justify-content: center;

        .form-wrapper {
            width: 800px;
            flex-direction: column;
            display: flex;

            .form-item {
                display: flex;
                align-items: flex-start;

                label {
                    width: 200px;
                    margin-top: 15px;

                    .sublabel {
                        font-size: 12px;
                    }
                }

            }

            .image-previews {
                display: flex;
                flex-wrap: wrap;

                img {
                    width: 170px;
                    height: 170px;
                    border: 1px solid black;
                    margin: 2px 4px;
                    cursor: pointer;
                }
            }

            input, textarea, select {
                margin: 5px 0;
                padding: 10px;
                border-radius: 8px;
                flex-grow: 1;

                outline-offset: 0px;
                outline: -webkit-focus-ring-color auto 1px;
            }
            

            label.file-upload-button, input[type="submit"] {
                border-radius: 8px;
                border: 1px solid black;
                background-color: #D4E7FD;
                color: black;
                font-weight: bold;
                text-decoration: none;
                width: fit-content;
                padding: 16px;
                margin: 10px 0;
                cursor: pointer;
            }

            input[type="submit"] {
                align-self: flex-end;
            }

        }

    }
}

@media only screen and (max-width: 700px) {
    .contact-container {
        .form-container {
            .form-wrapper {
                .form-item {
                    flex-direction: column;
                    align-items: unset;

                    label {
                        width: auto;
                    }
                }
            }
        }
    }
}