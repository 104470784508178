.flashes-container {
    width: 100%;
}

.loading {
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    position: absolute;
    justify-content: center;
}

.single-use {
    width: 10%;
    background-color: #4CAE4C;
    border-radius: 100%;
    padding: 5px;
    top: 0;
    left: 5px;
    margin: 5px;
    opacity: 0.7;

    svg {
        fill: white;
        width: 100%;
        height: 100%;

        path {
            stroke: none;
        }
    }
}

.legend {
    display: flex;
    justify-content: center;
    align-items: center;

    .legend-item {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 5px;
    }

    svg {
        margin: 10px;
    }

    .single-use, .single-use svg {
        margin: 0;
        opacity: 1;
        width: 25px;
        height: 25px;
    }

    .single-use {
        margin: 5px;
    }
}

.flashes-wrapper {
    display: block;
    columns: 4;
    column-gap: 10px;
    width: 100%;
    padding: 0 10vw;
    margin-top: 10px;

    .flash {
        margin: 10px 0;
        border-radius: 8px;
        overflow: hidden;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        position: relative;

        &:nth-child(1) {
            margin-top: 0;
        }

        .single-use {
            position: absolute;
        }

        img {
            width: 100%;
            height: 100%;

            object-fit: contain;
        }

        .size {
            display: flex;
            align-items: center;
            flex-direction: column;

            .size-label {
                margin-bottom: -18px;
            }

            svg {
                margin: 5px 0;
            }
        }
    }
}

@media only screen and (max-width: 700px) {
    .flashes-wrapper {
        padding: 0 5vw;
        columns: 2;
    }
}